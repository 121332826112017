import { Controller } from "@hotwired/stimulus";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static targets = ["body"];

  initialize() {
    let ll_threshold = 300; //default

    // lazy load
    this.ll = new LazyLoad({
      threshold: ll_threshold,
      elements_selector: ".lazy",
      callback_loaded: (element) => {
        if (element.classList.contains("loading")) {
          element.classList.remove("loading");
        } else {
          const loader = element.closest(".loading");
          if (loader) {
            loader.classList.remove("loading");
          }
        }
      },
      callback_error: (element) => {
        if (element.classList.contains("loading")) {
          element.classList.remove("loading");
        } else {
          const loader = element.closest(".loading");
          if (loader) {
            loader.classList.remove("loading");
          }
        }
      },
    });
  }

  updateLazyLoad() {
    this.ll.update();
  }

  vh() {
    const vh = window.innerHeight * 0.01;
    this.element.style.setProperty("--vh", `${vh}px`);
  }

  bodyNoScroll() {
    disableBodyScroll(this.bodyTarget);
  }

  bodyScroll() {
    enableBodyScroll(this.bodyTarget);
  }

  /* Click highlight --> scroll to programme detail */
  handleClickHighlight(e) {
    const id = e.currentTarget.dataset.programmeId,
      parent = e.currentTarget.closest('.guide__highlight').parentNode,
      content = parent.querySelector(`.guide__show-button[data-programme-id="${id}"]`);

    if (content) {
      if (!content.classList.contains('is-active')) content.click();

      const offset = parseInt(window.getComputedStyle(content).getPropertyValue('top').slice(0, -2)),
        scrollY = content.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({ top: scrollY, behavior: 'smooth' });
    }
  }
}
